import React from 'react'
import Privacy from '../../components/Privacy'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'

function privacy() {
  return (
    <Layout border='2px solid #424242' lang='en-us'>
        <Seo title='Privacy Policy | Shukran' descriptionEn='Privacy Policy'  descriptionFr='Politique de confidentialité'/>
        <Privacy fr={false}/>
    </Layout>
  )
}

export default privacy